var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"nodeForm",attrs:{"rules":_vm.rules,"label-position":"top","label-width":"100px","model":_vm.nodeToBind}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"node_name","label":_vm.__('Name')}},[_c('el-input',{model:{value:(_vm.nodeToBind.node_name),callback:function ($$v) {_vm.$set(_vm.nodeToBind, "node_name", $$v)},expression:"nodeToBind.node_name"}})],1)],1)],1),_c('el-tabs',{staticClass:"tabs",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":_vm.__('Queue'),"name":"queue"}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.queue_id","label":_vm.__('Select queue')}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%"},attrs:{"default-first-option":"","filterable":"","placeholder":_vm.__('Please select a queue')},model:{value:(_vm.nodeToBind.add_to_queue_node.data.queue_id),callback:function ($$v) {_vm.$set(_vm.nodeToBind.add_to_queue_node.data, "queue_id", $$v)},expression:"nodeToBind.add_to_queue_node.data.queue_id"}},[_c('template',{slot:"prefix"},[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":_vm.getRowIconById(
                        _vm.nodeToBind.add_to_queue_node.data.queue_id
                      )}})]),_vm._l((_vm.generateQueueOptions),function(group){return _c('el-option-group',{key:group.label,attrs:{"label":group.label}},_vm._l((group.options),function(item){return _c('el-option',{key:item.queue_id,attrs:{"label":item.queue_name,"value":item.queue_id}})}),1)})],2)],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.waiter_token_variable.variable_id","label":_vm.__('Check waiter token')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('waiter_token_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange($event, 'waiter_token_variable')}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.callback_phone_number_variable.variable_id","label":_vm.__('Callback phone number')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([
                    'array',
                    'audio',
                    'payment',
                    'secure'
                  ]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('callback_phone_number_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                    $event,
                    'callback_phone_number_variable'
                  )}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.from_phone_number_variable.variable_id","label":_vm.__('From phone number')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([
                    'array',
                    'audio',
                    'payment',
                    'secure'
                  ]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('from_phone_number_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                    $event,
                    'from_phone_number_variable'
                  )}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.display_name_variable.variable_id","label":_vm.__('Display name')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([
                    'array',
                    'audio',
                    'payment',
                    'secure'
                  ]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('display_name_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange($event, 'display_name_variable')}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":16}},[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.dial_attempts","label":_vm.__('Dial attempts')}},[_c('el-slider',{staticStyle:{"margin-left":"10px"},attrs:{"show-input":"","show-stops":"","min":1,"max":10},model:{value:(_vm.nodeToBind.add_to_queue_node.data.dial_attempts),callback:function ($$v) {_vm.$set(_vm.nodeToBind.add_to_queue_node.data, "dial_attempts", $$v)},expression:"nodeToBind.add_to_queue_node.data.dial_attempts"}})],1)],1)],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Response'),"name":"response"}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.response_code_variable.variable_id","label":_vm.__('Response code')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([
                    'array',
                    'audio',
                    'system',
                    'payment'
                  ]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('response_code_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange($event, 'response_code_variable')}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.response_message_variable.variable_id","label":_vm.__('Response message')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([
                    'array',
                    'audio',
                    'system',
                    'payment'
                  ]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('response_message_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange($event, 'response_message_variable')}}})],1)],1)],1)],1),_c('el-tab-pane',{attrs:{"label":_vm.__('Premium'),"name":"premium","disabled":this.isPremiumDisabled}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.caller_information_text_variable.variable_id","label":_vm.__('Customer info text')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.generateVariableOptions([]),"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('caller_information_text_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                    $event,
                    'caller_information_text_variable'
                  )}}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"prop":"add_to_queue_node.data.caller_information_audio_variable.variable_id","label":_vm.__('Customer info audio')}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.audioVariables,"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected('caller_information_audio_variable'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                    $event,
                    'caller_information_audio_variable'
                  )}}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }