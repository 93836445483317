<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane :label="__('Queue')" name="queue">
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.queue_id"
                :label="__('Select queue')"
              >
                <el-select
                  v-model="nodeToBind.add_to_queue_node.data.queue_id"
                  v-loading="isLoading"
                  default-first-option
                  filterable
                  :placeholder="__('Please select a queue')"
                  style="width: 100%;"
                  ><template slot="prefix"
                    ><img
                      style="vertical-align: middle"
                      :src="
                        getRowIconById(
                          nodeToBind.add_to_queue_node.data.queue_id
                        )
                      "
                  /></template>
                  <el-option-group
                    v-for="group in generateQueueOptions"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.queue_id"
                      :label="item.queue_name"
                      :value="item.queue_id"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.waiter_token_variable.variable_id"
                :label="__('Check waiter token')"
              >
                <create-or-select
                  :items="generateVariableOptions([])"
                  label="variable_name"
                  value="variable_id"
                  :current_select="variableSelected('waiter_token_variable')"
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange($event, 'waiter_token_variable')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.callback_phone_number_variable.variable_id"
                :label="__('Callback phone number')"
              >
                <create-or-select
                  :items="
                    generateVariableOptions([
                      'array',
                      'audio',
                      'payment',
                      'secure'
                    ])
                  "
                  label="variable_name"
                  value="variable_id"
                  :current_select="
                    variableSelected('callback_phone_number_variable')
                  "
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange(
                      $event,
                      'callback_phone_number_variable'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.from_phone_number_variable.variable_id"
                :label="__('From phone number')"
              >
                <create-or-select
                  :items="
                    generateVariableOptions([
                      'array',
                      'audio',
                      'payment',
                      'secure'
                    ])
                  "
                  label="variable_name"
                  value="variable_id"
                  :current_select="
                    variableSelected('from_phone_number_variable')
                  "
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange(
                      $event,
                      'from_phone_number_variable'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.display_name_variable.variable_id"
                :label="__('Display name')"
              >
                <create-or-select
                  :items="
                    generateVariableOptions([
                      'array',
                      'audio',
                      'payment',
                      'secure'
                    ])
                  "
                  label="variable_name"
                  value="variable_id"
                  :current_select="variableSelected('display_name_variable')"
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange($event, 'display_name_variable')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="16">
              <el-form-item
                prop="add_to_queue_node.data.dial_attempts"
                :label="__('Dial attempts')"
              >
                <el-slider
                  style="margin-left:10px"
                  v-model="nodeToBind.add_to_queue_node.data.dial_attempts"
                  show-input
                  show-stops
                  :min="1"
                  :max="10"
                >
                </el-slider>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="__('Response')" name="response">
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.response_code_variable.variable_id"
                :label="__('Response code')"
              >
                <create-or-select
                  :items="
                    generateVariableOptions([
                      'array',
                      'audio',
                      'system',
                      'payment'
                    ])
                  "
                  label="variable_name"
                  value="variable_id"
                  :current_select="variableSelected('response_code_variable')"
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange($event, 'response_code_variable')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.response_message_variable.variable_id"
                :label="__('Response message')"
              >
                <create-or-select
                  :items="
                    generateVariableOptions([
                      'array',
                      'audio',
                      'system',
                      'payment'
                    ])
                  "
                  label="variable_name"
                  value="variable_id"
                  :current_select="
                    variableSelected('response_message_variable')
                  "
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange($event, 'response_message_variable')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Premium')"
          name="premium"
          :disabled="this.isPremiumDisabled"
        >
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.caller_information_text_variable.variable_id"
                :label="__('Customer info text')"
              >
                <create-or-select
                  :items="generateVariableOptions([])"
                  label="variable_name"
                  value="variable_id"
                  :current_select="
                    variableSelected('caller_information_text_variable')
                  "
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange(
                      $event,
                      'caller_information_text_variable'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col>
              <el-form-item
                prop="add_to_queue_node.data.caller_information_audio_variable.variable_id"
                :label="__('Customer info audio')"
              >
                <create-or-select
                  :items="audioVariables"
                  label="variable_name"
                  value="variable_id"
                  :current_select="
                    variableSelected('caller_information_audio_variable')
                  "
                  :placeholder="__('Select variable or type a new name')"
                  :new-item-message="__('new variable')"
                  style="width: 100%;"
                  @change="
                    variableSelectionChange(
                      $event,
                      'caller_information_audio_variable'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import CreateOrSelect from "../components/CreateOrSelect";
import { mapActions, mapGetters, mapState } from "vuex";
// import { filterRowsIfSomeKeyValueIsAbsent, getSubKeyObject } from "@/utils/collection";

export default {
  mixins: [BaseNode],
  components: {
    CreateOrSelect
  },
  data() {
    return {
      rules: {
        add_to_queue_node: {
          data: {
            queue_id: {
              required: true,
              message: __("Please select a queue"),
              trigger: "blur"
            },
            waiter_token_variable: {
              variable_id: {
                required: true,
                message: __("Please select a variable for waiter token"),
                trigger: "blur"
              }
            },
            callback_phone_number_variable: {
              variable_id: {
                required: true,
                // eslint-disable-next-line
                message: __("Please select a variable for callback phone number"),
                trigger: "blur"
              }
            }
          }
        }
      },
      activeTab: "queue"
    };
  },
  computed: {
    ...mapState("qforme", {
      queues: state => state.queues,
      isLoading: state => state.loading
    }),

    ...mapGetters("qforme", {
      queue: "queue"
    }),
    isPremiumDisabled() {
      let queue = _.find(this.queues, [
        "queue_id",
        this.nodeToBind.add_to_queue_node.data.queue_id
      ]);
      return queue && queue.ac_plan.data[0].plan_type === "QforMe Standard";
    },

    generateQueueOptions() {
      let standard = _.filter(this.queues, function(o) {
        return o.ac_plan.data[0].plan_type === "QforMe Standard";
      });
      let premium = _.filter(this.queues, function(o) {
        return o.ac_plan.data[0].plan_type === "QforMe Premium";
      });
      // return standard;
      return [
        {
          label: __("Standard"),
          options: standard
        },
        {
          label: __("Premium"),
          options: premium
        }
      ];
    }
  },
  methods: {
    ...mapActions("qforme", {
      getQueues: "getQueues"
    }),
    generateVariableOptions(exclude_types) {
      return _.filter(this.variables, variable => {
        if (_.isEmpty(exclude_types))
          exclude_types = ["array", "audio", "system", "payment", "secure"];
        return !exclude_types.includes(variable.variable_type);
      });
    },
    variableSelected(var_name) {
      let var_object = this.nodeToBind.add_to_queue_node.data[var_name];
      if (!var_object) {
        return "";
      } else {
        const { variable_id, variable_name } = var_object;
        return variable_id === -1 ? variable_name : variable_id;
      }
    },

    variableSelectionChange(option, var_name) {
      this.newVariableCreated = option.value === -1;
      let var_object = {
        variable_id: option.value,
        variable_name: option.label
      };
      if (_.isEmpty(var_object.variable_name)) {
        var_object.variable_id = "";
      }
      this.$set(this.nodeToBind.add_to_queue_node.data, var_name, var_object);
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      // cleanup keyword_matches
      return nodeToCleanUp;
    },

    getRowIcon(queue) {
      if (queue.ac_plan.data[0].plan_type === "QforMe Standard") {
        return require("@/assets/icons//icon-waiter.svg");
      } else if (queue.ac_plan.data[0].plan_type === "QforMe Premium") {
        return require("@/assets/icons//icon-premium-waiter.svg");
      }
    },

    getRowIconById(queue_id) {
      let queue = _.find(this.queues, ["queue_id", queue_id]);
      if (queue) {
        return this.getRowIcon(queue);
      }
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.add_to_queue_node)
    ) {
      // console.log(this.nodeToBind);
      this.$set(this.nodeToBind, "add_to_queue_node", {});
      this.$set(this.nodeToBind.add_to_queue_node, "data", {});

      this.$set(this.nodeToBind.add_to_queue_node.data, "queue_id", null);
      this.$set(this.nodeToBind.add_to_queue_node.data, "dial_attempts", null);
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "response_code_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "response_message_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "waiter_token_variable",
        null
      );

      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "callback_phone_number_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "from_phone_number_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "display_name_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "caller_information_text_variable",
        null
      );
      this.$set(
        this.nodeToBind.add_to_queue_node.data,
        "caller_information_audio_variable",
        null
      );

      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.ADD_TO_QUEUE.NODE_TYPE
      );
    }
  },
  mounted() {
    this.getQueues();
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
